import React from "react"
import {graphql, Link } from "gatsby"


import Layout from '@common/Layout';

import { Container } from '@components/global';
import Footer from '@components/sections/Footer';

import styled from 'styled-components';

import DrobeboxLogo from '@static/logo_white.png';

const StaticPageTemplate = ({ data }) => {
    const page = data.markdownRemark;
    return (
        <Layout title={page.frontmatter.title} description={page.excerpt}>
          <Nav>
            <Link to='/'><img src={DrobeboxLogo} alt="Logo" style={{maxHeight: '90px'}}/></Link>
          </Nav>
          <StaticContainer>
            <h1>
                {page.frontmatter.title}
            </h1>
            <Content dangerouslySetInnerHTML={{ __html: page.html }}/>
          </StaticContainer>
          <Footer/>
        </Layout>
    )
};

export default StaticPageTemplate

export const pageQuery = graphql`
  query StaticPageBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
      }
    }
  }
`
const StaticContainer = styled(Container) `
  flex-direction: column;
  white-space: pre-wrap;
  padding: 3rem 1rem;
  margin-top: 106px;
  h1 {
    padding: 1rem;
    text-align: center;
  }
  @media (max-width: ${props => props.theme.screen.lg}) {
    padding: 1rem;
  }
`

const Content = styled.div `
  padding: 3rem;
  font-family: 'Montserrat';
  @media (max-width: ${props => props.theme.screen.lg}) {
    padding: 1rem;
  }
`

const Nav = styled.nav`
  display: flex;
  justify-content: center;
  padding: 8px 16px;
  background-color: ${props => props.theme.color.black.regular};
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
`;
